import { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { emptyMessage } from 'actions';
import {
    approveFinancing, approveFinancingPurchase, getFintechCompRelation,
    getInvoiceTaxInformation
} from '../../actions/fintechAction';




const Persetujuan = (props) => {
    let totalTax = 0;

    const [top, setTop] = useState({
        id: -1,
        value: -1,
        label: ''
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,

        height: 595,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        overflow: "auto",
        p: 4,
    };

    const schemeOptions = [
        {
            "id": 1,
            "value": "conventional",
            "label": "Konvensional"
        },
        {
            "id": 2,
            "value": "profit_sharing",
            "label": "Bagi Hasil"
        },

    ]


    const [interestDurasi, setInterestDurasi] = useState(0);
    const [interestTahunan, setInterestTahunan] = useState(0);
    const [approvedAmount, setApprovedAmount] = useState(0);
    const [minimumPeriod, setMinimumPeriod] = useState(0);
    const [penaltiBulanan, setPenaltiBulanan] = useState(0);
    const [profitSharingContractor, setProfitSharingContractor] = useState(0);
    const [profitSharingFintech, setProfitSharingFintech] = useState(0);
    const [scheme, setScheme] = useState(schemeOptions[0]);
    const [profitSharing, setProfitSharing] = useState(false);

    const [showError, setShowError] = useState({
        top: false,
        interestDurasi: false,
        interestTahunan: false,
        approvedAmount: false,
        all: false,
    });
    const [helperMsg, setHelperMsg] = useState({
        top: 'Durasi pinjaman harus dipilih.',
        interestDurasi: 'Bunga dalam masa pinjaman harus diisi',
        interestTahunan: 'Bunga tahunan harus diisi',
        approvedAmount: 'Jumlah harus diisi',
        all: 'Masih terdapat informasi wajib yang kosong.',
        fail: 'ERROR'
    });

    const dispatch = useDispatch();

    const fintechCompRelation = useSelector(
        state => {
            return state.fintechCompRelation;
        },
        shallowEqual

    );


    const setProfitSharingProportion = (contractor, fintech) => {
        if (contractor !== null) {
            setProfitSharingContractor(contractor);
            const fintechSharing = 100 - contractor;
            setProfitSharingFintech(fintechSharing);
        } else if (fintech !== null) {
            setProfitSharingFintech(fintech);
            const contractorSharing = 100 - fintech;
            setProfitSharingContractor(contractorSharing);
        }
    }



    const invoiceTaxInformation = useSelector(
        state => {
            return state.invoiceTaxInformation;
        },
        shallowEqual
    )

    const topOptions = [
        {
            id: 0,
            value: 7,
            label: 'Jatuh Tempo 7 Hari'
        },
        {
            id: 1,
            value: 14,
            label: 'Jatuh Tempo 14 Hari'
        },
        {
            id: 2,
            value: 21,
            label: 'Jatuh Tempo 21 Hari'
        },
        {
            id: 3,
            value: 30,
            label: 'Jatuh Tempo 30 Hari'
        },
        {
            id: 4,
            value: 45,
            label: 'Jatuh Tempo 45 Hari'
        },
        {
            id: 5,
            value: 60,
            label: 'Jatuh Tempo 60 Hari'
        },
        {
            id: 6,
            value: 75,
            label: 'Jatuh Tempo 75 Hari'
        },
        {
            id: 7,
            value: 90,
            label: 'Jatuh Tempo 90 Hari'
        },
        {
            id: 8,
            value: 105,
            label: 'Jatuh Tempo 105 Hari'
        },
        {
            id: 9,
            value: 120,
            label: 'Jatuh Tempo 120 Hari'
        },
    ];

    const message = useSelector(
        state => {
            return state.message;
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(getFintechCompRelation(props.rowfinancing.invoicing_company_id));
        dispatch(getInvoiceTaxInformation(props.rowfinancing.invoice_id))
    }, [])

    const calculateTax = (invoices) => {
        totalTax = 0;
        invoices.map((invoice) => {
            totalTax += parseFloat(invoice.header.inclusive_tax_amount)
        })
    }

    useEffect(() => {
        calculateTax(invoiceTaxInformation);
        setApprovedAmount(parseFloat(
            (props.rowfinancing.amount - totalTax) * fintechCompRelation.financing_percentage / 100).toFixed(0));
        setInterestTahunan(fintechCompRelation.interest_annum);
        if (props.rowfinancing.term_of_payment <= 7) {
            setTop({
                id: 0,
                value: 7,
                label: 'Jatuh Tempo 7 Hari'
            },);
            setInterestDurasi(fintechCompRelation.interest_base * 7 / 30);
        } else if ((props.rowfinancing.term_of_payment > 7) && (props.rowfinancing.term_of_payment <= 14)) {
            setTop({
                id: 1,
                value: 14,
                label: 'Jatuh Tempo 14 Hari'
            },);
            setInterestDurasi(fintechCompRelation.interest_base * 14 / 30);
        } else if ((props.rowfinancing.term_of_payment > 14) && (props.rowfinancing.term_of_payment <= 21)) {
            setTop({
                id: 2,
                value: 21,
                label: 'Jatuh Tempo 21 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 21 / 30);
        } else if ((props.rowfinancing.term_of_payment > 21) && (props.rowfinancing.term_of_payment <= 30)) {
            setTop({
                id: 3,
                value: 30,
                label: 'Jatuh Tempo 30 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base);
        } else if ((props.rowfinancing.term_of_payment > 30) && (props.rowfinancing.term_of_payment <= 45)) {
            setTop({
                id: 4,
                value: 45,
                label: 'Jatuh Tempo 45 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 45 / 30);
        } else if ((props.rowfinancing.term_of_payment > 45) && (props.rowfinancing.term_of_payment <= 60)) {
            setTop({
                id: 5,
                value: 60,
                label: 'Jatuh Tempo 60 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 60 / 30);
        } else if ((props.rowfinancing.term_of_payment > 60) && (props.rowfinancing.term_of_payment <= 75)) {
            setTop({
                id: 6,
                value: 75,
                label: 'Jatuh Tempo 75 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 75 / 30);
        } else if ((props.rowfinancing.term_of_payment > 75) && (props.rowfinancing.term_of_payment <= 90)) {
            setTop({
                id: 7,
                value: 90,
                label: 'Jatuh Tempo 90 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 90 / 30);
        } else if ((props.rowfinancing.term_of_payment > 90) && (props.rowfinancing.term_of_payment <= 105)) {
            setTop({
                id: 8,
                value: 105,
                label: 'Jatuh Tempo 105 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 105 / 30);
        } else if ((props.rowfinancing.term_of_payment > 105) && (props.rowfinancing.term_of_payment <= 120)) {
            setTop({
                id: 9,
                value: 120,
                label: 'Jatuh Tempo 120 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 120 / 30);
        }

        if (fintechCompRelation.other) {
            fintechCompRelation.other.forEach((item) => {
                if (item.name === "minimum_period" || item.name === "minimum_duration") {
                    setMinimumPeriod(item.value);
                } else if (item.name === "penalty") {
                    setPenaltiBulanan(item.value);
                }
            });
        }
    }, [fintechCompRelation, invoiceTaxInformation]);

    useEffect(() => {
        dispatch(emptyMessage());
        switch (message.status) {
            case 'APPROVE_FINANCING':
                props.onClose();
                return;
            case 'ERROR':
                setShowError(
                    {
                        ...showError,
                        fail: true
                    }
                );
                return;
            default:
                return;
        }
    }, [message]);


    useEffect(() => {
        if (interestDurasi > 0) {
            setShowError({ ...showError, interestDurasi: false });
        }
    }, [interestDurasi]);

    useEffect(() => {
        if (interestTahunan > 0) {
            setShowError({ ...showError, interestTahunan: false });
        }
    }, [interestTahunan]);

    useEffect(() => {
        if (
            (interestDurasi > 0) &&
            (interestTahunan > 0) &&
            (top.id !== -1)
        ) {
            setShowError({ interestTahunan: false, interestDurasi: false, all: false });
        }
    }, [interestDurasi, interestTahunan, top]);

    const onChangeTOP = (e, v) => {
        if (v === null) {
            setShowError({ ...showError, top: true });
        } else {
            setShowError({ ...showError, top: false });
            setTop(v);
        }

    }

    const onSchemeChange = (e, v) => {
        if (v.value === "profit_sharing") {
            setProfitSharing(true);
        } else {
            setProfitSharing(false);
        }
        setScheme(v);
    }

    const onAgree = () => {
        if (
            (top.id !== -1) &&
            (interestDurasi > 0) &&
            (interestTahunan > 0)
        ) {

            var other = [];
            if (minimumPeriod > 0) {
                other = [...other, {
                    name: "minimum_period",
                    type: "number",
                    value: Number(minimumPeriod)
                }]
            }
            if (penaltiBulanan > 0) {
                other = [...other, {
                    name: "penalty",
                    type: "percentage",
                    value: Number(penaltiBulanan)
                }]
            }
            if (profitSharing === true) {
                other = [...other, {
                    name: "profit_sharing",
                    type: "number",
                    value: 1
                },
                {
                    name: "profit_sharing_contractor",
                    type: "percentage",
                    value: Number(profitSharingContractor)
                },
                {
                    name: "profit_sharing_fintech",
                    type: "percentage",
                    value: Number(profitSharingFintech)
                }]
            }

            const term = {
                financing_id: Number(props.idfinancing),
                term_of_payment: Number(top.value),
                interest_base: Number(interestDurasi),
                interest_annum: Number(interestTahunan),
                approved_amount: Number(approvedAmount),
                other: other
            };

            if (props.rowfinancing.type === 'invoice') {
                dispatch(approveFinancing(term));
            } else if (props.rowfinancing.type === 'purchase') {
                dispatch(approveFinancingPurchase(term));
            }

        } else {
            setShowError({
                top: top.id === -1,
                interestDurasi: interestDurasi <= 0,
                interestTahunan: interestTahunan <= 0,
                all: true,
            });
        }
    }

    const onInterestDurasiBlur = () => {
        if (interestDurasi <= 0) {
            setShowError({ ...showError, interestDurasi: true });
        }
    }

    const onInterestTahunanBlur = () => {
        if (interestTahunan <= 0) {
            setShowError({ ...showError, interestTahunan: true });
        }
    }

    const onApprovedAmountBlur = () => {
        if (approvedAmount <= 0) {
            setShowError({ ...showError, approvedAmount: true });
        }
    }

    const onMinimumPeriodBlur = () => {
        if (minimumPeriod <= 0) {
            setMinimumPeriod(0);
        }
    }

    const onPenaltiBulananBlur = () => {
        if (penaltiBulanan <= 0) {
            setPenaltiBulanan(0);
        }
    }

    const onProfitSharingContractorBlur = () => {
        if (profitSharingContractor <= 0) {
            setProfitSharingContractor(0);
        }
    }

    const onProfitSharingFintechBlur = () => {
        if (profitSharingFintech <= 0) {
            setProfitSharingFintech(0);
        }
    }

    return (
        <div>
            <MDBox sx={style}>
                <Grid container direction="column" justifyContent="space-around" spacing={3}>
                    <Grid item>
                        <MDBox>
                            <MDTypography variant="title" fontWeight="medium">
                                Setujui Pengajuan
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item>
                        <MDBox sx={{ width: '100%' }}>
                            <Grid container direction="column" alignItems="center" justifyContent="space-around" spacing={2}>

                                <Grid item mb={2}>
                                    <Autocomplete
                                        disablePortal
                                        id="profit_sharing"
                                        options={schemeOptions}
                                        value={scheme}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        onChange={onSchemeChange}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Skema" />}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.top ? helperMsg.top : ''} </MDTypography>
                                </Grid>

                                {profitSharing === true ? (
                                    <>
                                        <Grid item mb={2}>
                                            <MDInput
                                                id="profit_sharing_contractor"
                                                name="profit_sharing_contractor"
                                                label="Bagi Hasil Kontraktor (%)"
                                                value={profitSharingContractor}
                                                type="number"
                                                onChange={(e) => setProfitSharingProportion(e.target.value, null)}
                                                onBlur={onProfitSharingContractorBlur}
                                                placeholder="Bagi Hasil Untuk Kontraktor dalam persen"
                                                required
                                                sx={{ width: 300 }}
                                            />
                                            <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.approvedAmount ? helperMsg.approvedAmount : ''} </MDTypography>
                                        </Grid>

                                        <Grid item mb={2}>
                                            <MDInput
                                                id="profit_sharing_fintech"
                                                name="profit_sharing_fintech"
                                                label="Bagi Hasil Fintech (%)"
                                                value={profitSharingFintech}
                                                type="number"
                                                onChange={(e) => setProfitSharingProportion(null, e.target.value)}
                                                onBlur={onProfitSharingFintechBlur}
                                                placeholder="Bagi Hasil Untuk Fintech dalam persen"
                                                required
                                                sx={{ width: 300 }}
                                            />
                                            <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.approvedAmount ? helperMsg.approvedAmount : ''} </MDTypography>
                                        </Grid>
                                    </>) : ''}


                                <Grid item mb={2}>
                                    <Autocomplete
                                        disablePortal
                                        id="select_top"
                                        options={topOptions}
                                        value={top}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        onChange={onChangeTOP}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Durasi Pinjaman" />}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.top ? helperMsg.top : ''} </MDTypography>
                                </Grid>


                                {profitSharing !== true ? (
                                    <>
                                        <Grid item mb={2}>
                                            <MDInput
                                                id="interest_durasi"
                                                name="interest_durasi"
                                                label="Bunga Durasi Pinjaman (%)"
                                                value={interestDurasi}
                                                type="number"
                                                onChange={(e) => setInterestDurasi(e.target.value)}
                                                onBlur={onInterestDurasiBlur}
                                                placeholder="Bunga yang disetujui selama durasi pinjaman"
                                                required
                                                sx={{ width: 300 }}
                                            />
                                            <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestDurasi ? helperMsg.interestDurasi : ''} </MDTypography>
                                        </Grid>
                                        <Grid item mb={2}>
                                            <MDInput
                                                id="interest_tahunan"
                                                name="interest_tahunan"
                                                label="Bunga 1 Tahun (%)"
                                                value={interestTahunan}
                                                type="number"
                                                onBlur={onInterestTahunanBlur}
                                                onChange={(e) => setInterestTahunan(e.target.value)}
                                                placeholder="Bunga per tahun "
                                                required
                                                sx={{ width: 300 }}
                                            />
                                            <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestTahunan ? helperMsg.interestTahunan : ''} </MDTypography>
                                        </Grid>
                                    </>) : ''}
                                {/* <Grid item mb={2} display={props.rowfinancing.type==='invoice'?'none':'grid'}> */}
                                <Grid item mb={2}>
                                    <MDInput
                                        id="approved_amount"
                                        name="approved_amount"
                                        label="Jumlah yang Disetujui"
                                        value={approvedAmount}
                                        type="number"
                                        onChange={(e) => setApprovedAmount(e.target.value)}
                                        onBlur={onApprovedAmountBlur}
                                        placeholder="Limit pengajuan untuk order penjualan"
                                        required
                                        sx={{ width: 300 }}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.approvedAmount ? helperMsg.approvedAmount : ''} </MDTypography>
                                </Grid>
                                <Grid item mb={2}>
                                    <MDInput
                                        id="minimum_period"
                                        name="minimum_period"
                                        label="Durasi Pinjaman Minimum (hari)"
                                        value={minimumPeriod}
                                        type="number"
                                        onBlur={onMinimumPeriodBlur}
                                        onChange={(e) => setMinimumPeriod(e.target.value)}
                                        placeholder="Durasi pinjaman minimum"
                                        sx={{ width: 300 }}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestTahunan ? helperMsg.interestTahunan : ''} </MDTypography>
                                </Grid>
                                <Grid item mb={2}>
                                    <MDInput
                                        id="penalti_bulanan"
                                        name="penalti_bulanan"
                                        label="Penalti per 30 hari (%)"
                                        value={penaltiBulanan}
                                        type="number"
                                        onBlur={onPenaltiBulananBlur}
                                        onChange={(e) => setPenaltiBulanan(e.target.value)}
                                        placeholder="Penalti tiap 30 hari "
                                        sx={{ width: 300 }}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestTahunan ? helperMsg.interestTahunan : ''} </MDTypography>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid>
                <MDBox alignItems="center" px={2}>
                    <MDBox mt={1}>
                        <MDButton fullWidth variant="contained" color="info" size="small" onClick={onAgree}>
                            Simpan
                        </MDButton>
                    </MDBox>
                    <MDBox mt={1}>
                        <MDButton fullWidth variant="outlined" color="info" size="small" onClick={props.onClose}>
                            Batal
                        </MDButton>
                    </MDBox>
                    <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>{showError.all ? helperMsg.all : ''} </MDTypography>
                    <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>{showError.fail ? helperMsg.fail : ''} </MDTypography>
                </MDBox>
            </MDBox>
        </div>
    );
};

export default Persetujuan;